import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
const initialState = {
  ordertotal: [],
  bookedorder: [],
  processingorder: [],
  dispatchorder: [],
  outfordeliveryorder: [],
  deliveredorder: [],
  cancelledorder: [],
  orderItemtotal: [],
  singleOrder: JSON.parse(localStorage.getItem("singleOrder"))
    ? JSON.parse(localStorage.getItem("singleOrder"))
    : "",
  isLoading: true,
  isOrderLoading: true,
  isOrderForLoading: true,
};

export const getOrder = createAsyncThunk("order/getOrder", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/order/all`;
    const resp = await axios(url);
    return resp.data.orders;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const postOrder = createAsyncThunk(
  "order/postOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/new`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("order Not create");
    }
  }
);

export const updateOrder = createAsyncThunk(
  "Order/updateOrder",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/${formData.orderid}`;

      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("order Not Updated");
    }
  }
);

export const updateOrdersingleItem = createAsyncThunk(
  "Order/updateOrdersingleItem",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/order/updatesingleorderitem`;

      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("order Not Updated");
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setSingleOrder(state, action) {
      state.singleOrder = action.payload;
      localStorage.setItem("singleOrder", JSON.stringify(state.singleOrder));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.ordertotal = action.payload;
        state.bookedorder = state.ordertotal.filter(
          (order) => order.Status === 1
        );
        state.processingorder = state.ordertotal.filter(
          (order) => order.Status === 2
        );
        state.dispatchorder = state.ordertotal.filter(
          (order) => order.Status === 3
        );
        state.outfordeliveryorder = state.ordertotal.filter(
          (order) => order.Status === 4
        );
        state.deliveredorder = state.ordertotal.filter(
          (order) => order.Status === 5
        );
        state.cancelledorder = state.ordertotal.filter(
          (order) => order.Status === 0
        );

        let itemArray = [];
        for (let index = 0; index < state.processingorder.length; index++) {
          const element = state.processingorder[index].OrderProducts;
          itemArray.push(element);
        }
        state.orderItemtotal = itemArray.flat(1);

        state.isLoading = false;
        state.isOrderLoading = false;
      })
      .addCase(getOrder.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(postOrder.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.ordertotal = [...state.ordertotal, action.payload.order];
          state.bookedorder = state.ordertotal.filter(
            (order) => order.Status === 1
          );
          state.processingorder = state.ordertotal.filter(
            (order) => order.Status === 2
          );
          state.dispatchorder = state.ordertotal.filter(
            (order) => order.Status === 3
          );
          state.outfordeliveryorder = state.ordertotal.filter(
            (order) => order.Status === 4
          );
          state.deliveredorder = state.ordertotal.filter(
            (order) => order.Status === 5
          );
          state.cancelledorder = state.ordertotal.filter(
            (order) => order.Status === 0
          );

          let itemArray = [];
          for (let index = 0; index < state.processingorder.length; index++) {
            const element = state.processingorder[index].OrderProducts;
            itemArray.push(element);
          }
          state.orderItemtotal = itemArray.flat(1);
        }
        state.isOrderLoading = false;
      })
      .addCase(postOrder.rejected, (state) => {
        state.isOrderLoading = false;
      })

      .addCase(updateOrder.pending, (state) => {
        state.isOrderForLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.ordertotal = state.ordertotal.filter(
            (order) => order._id !== action.payload.order._id
          );
          state.ordertotal = [...state.ordertotal, action.payload.order];

          // Update order lists based on status
          state.bookedorder = state.ordertotal.filter(
            (order) => order.Status === 1
          );
          state.processingorder = state.ordertotal.filter(
            (order) => order.Status === 2
          );
          state.dispatchorder = state.ordertotal.filter(
            (order) => order.Status === 3
          );
          state.outfordeliveryorder = state.ordertotal.filter(
            (order) => order.Status === 4
          );
          state.deliveredorder = state.ordertotal.filter(
            (order) => order.Status === 5
          );
          state.cancelledorder = state.ordertotal.filter(
            (order) => order.Status === 0
          );

          let itemArray = [];
          for (let index = 0; index < state.processingorder.length; index++) {
            const element = state.processingorder[index].OrderProducts;
            itemArray.push(element);
          }
          state.orderItemtotal = itemArray.flat(1);
        }
        state.isOrderForLoading = false;
      })
      .addCase(updateOrder.rejected, (state) => {
        state.isOrderForLoading = false;
      })

      .addCase(updateOrdersingleItem.pending, (state) => {
        state.isOrderLoading = true;
      })
      .addCase(updateOrdersingleItem.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.ordertotal = state.ordertotal.filter(
            (order) => order._id !== action.payload.order._id
          );
          state.ordertotal = [...state.ordertotal, action.payload.order];

          state.bookedorder = state.ordertotal.filter(
            (order) => order.Status === 1
          );
          state.processingorder = state.ordertotal.filter(
            (order) => order.Status === 2
          );
          state.dispatchorder = state.ordertotal.filter(
            (order) => order.Status === 3
          );
          state.outfordeliveryorder = state.ordertotal.filter(
            (order) => order.Status === 4
          );
          state.deliveredorder = state.ordertotal.filter(
            (order) => order.Status === 5
          );
          state.cancelledorder = state.ordertotal.filter(
            (order) => order.Status === 0
          );

          let itemArray = [];
          for (let index = 0; index < state.processingorder.length; index++) {
            const element = state.processingorder[index].OrderProducts;
            itemArray.push(element);
          }
          state.orderItemtotal = itemArray.flat(1);
        }
        state.isOrderLoading = false;
      })
      .addCase(updateOrdersingleItem.rejected, (state) => {
        state.isOrderLoading = false;
      });
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
