import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login";
import { useDispatch, useSelector } from "react-redux";
import AddCategory from "./pages/admin-magangement/AddCategory";
import CategoryList from "./pages/admin-magangement/CategoryList";
import AddSubCategory from "./pages/admin-magangement/AddSubCategory";
import SubCategoryList from "./pages/admin-magangement/SubCategoryList";
import { categoryAll } from "./redux/Category/CategorySlice";
import EditCategory from "./pages/admin-magangement/EditCategory";
import EditSubCategory from "./pages/admin-magangement/EditSubCategory";
import { subCategoryAll } from "./redux/SubCategory/SubCategorySlice";
import AddProduct from "./pages/admin-magangement/AddProduct";
import { productAll } from "./redux/Product/ProductSlice";
import ProductList from "./pages/admin-magangement/ProductList";
import EditProduct from "./pages/admin-magangement/EditProduct";
import BookedOrder from "./pages/order/BookedOrder";
import { getOrder } from "./redux/Order/OrderSlice";
import ProcessedOrder from "./pages/order/ProcessedOrder";
import OutOfDeliveryOrder from "./pages/order/OutOfDeliveryOrder";
import DeliveredOrder from "./pages/order/DeliveredOrder";
import CancelledOrder from "./pages/order/CancelledOrder";
import DispatchedOrder from "./pages/order/DispatchedOrder";
import OrderDetails from "./pages/order/OrderDetails";
import AddSuperCategory from "./pages/admin-magangement/AddSuperCategory";
import SuperCategoryList from "./pages/admin-magangement/SuperCategoryList";
import EditSuperCategory from "./pages/admin-magangement/EditSuperCategory";
import AddBrand from "./pages/admin-magangement/AddBrand";
import BrandList from "./pages/admin-magangement/BrandList";
import { superCategoryAll } from "./redux/SuperCategory/SuperCategorySlice";
import { getBrand } from "./redux/Brand/BrandSlice";
import Addsubscription from "./pages/admin-magangement/Addsubscription";
import { getSubscription } from "./redux/subscription/subscriptionSlice";
import ListSubscription from "./pages/admin-magangement/ListSubscription";
import { getCoupon } from "./redux/coupon/couponSlice";
import AddCoupon from "./pages/admin-magangement/AddCoupon";
import ListCoupon from "./pages/admin-magangement/ListCoupon";
import AddSlider from "./pages/admin-magangement/sliders-banners/AddSlider";
import { getSlider } from "./redux/slider/sliderSlice";
import ListSlider from "./pages/admin-magangement/sliders-banners/ListSlider";
import UpdateSlider from "./pages/admin-magangement/sliders-banners/UpdateSlider";
import AddSubcatBanner from "./pages/admin-magangement/sliders-banners/AddSubcatBanner";
import { getSubCatBanner } from "./redux/subCatBanner/subCatBannerSlice";
import SubcatBannerList from "./pages/admin-magangement/sliders-banners/SubcatBannerList";
import SubcatBannerUpdate from "./pages/admin-magangement/sliders-banners/SubcatBannerUpdate";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(superCategoryAll());
        dispatch(categoryAll());
        dispatch(subCategoryAll());
        dispatch(getBrand());
        dispatch(productAll());
        dispatch(getSubscription());
        dispatch(getOrder());
        dispatch(getCoupon());
        dispatch(getSlider());
        dispatch(getSubCatBanner());
      } catch (error) {}
    };
    initialSetup();
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route
              path="/admin/addsupersategory"
              element={<AddSuperCategory />}
            />
            <Route
              path="/admin/listsupersategory"
              element={<SuperCategoryList />}
            />
            <Route
              path="/admin/updatesupersategory/:slugurl"
              element={<EditSuperCategory />}
            />
            <Route
              path="/admin/addcategory"
              element={<AddCategory />}
            />
            <Route
              path="/admin/listcategory"
              element={<CategoryList />}
            />
            <Route
              path="/admin/editcategory/:slugurl"
              element={<EditCategory />}
            />
            <Route
              path="/admin/addsubcategory"
              element={<AddSubCategory />}
            />
            <Route
              path="/admin/listsubcategory"
              element={<SubCategoryList />}
            />
            <Route
              path="/admin/editSubCategory/:slugurl"
              element={<EditSubCategory />}
            />

            <Route path="/admin/addBrand" element={<AddBrand />} />

            <Route path="/admin/listbrand" element={<BrandList />} />

            <Route path="/admin/addProduct" element={<AddProduct />} />
            <Route
              path="/admin/editProduct/:productId"
              element={<EditProduct />}
            />
            <Route
              path="/admin/productList"
              element={<ProductList />}
            />
            <Route
              path="/admin/addsubscription"
              element={<Addsubscription />}
            />
            <Route
              path="/admin/listsubscription"
              element={<ListSubscription />}
            />
            <Route
              path="/admin/addcoupon"
              element={<AddCoupon />}
            />
            <Route
              path="/admin/listcoupon"
              element={<ListCoupon />}
            />
            <Route
              path="/admin/addslider"
              element={<AddSlider />}
            />
            <Route
              path="/admin/listslider"
              element={<ListSlider />}
            />
            <Route
              path="/admin/updateslider/:slugUrl"
              element={<UpdateSlider />}
            />
            <Route
              path="/admin/addsubcatbanner"
              element={<AddSubcatBanner />}
            />
            <Route
              path="/admin/listsubcatbanner"
              element={<SubcatBannerList />}
            />
            <Route
              path="/admin/updatesubcatbanner/:slugUrl"
              element={<SubcatBannerUpdate />}
            />

            <Route path="/admin/order/bookedOrder" element={<BookedOrder />} />
            <Route
              path="/admin/order/processedOrder"
              element={<ProcessedOrder />}
            />
            <Route
              path="/admin/order/dispatchedOrder"
              element={<DispatchedOrder />}
            />
            <Route
              path="/admin/order/outOfDeliveryOrder"
              element={<OutOfDeliveryOrder />}
            />
            <Route
              path="/admin/order/deliveredOrder"
              element={<DeliveredOrder />}
            />
            <Route
              path="/admin/order/cancelledOrder"
              element={<CancelledOrder />}
            />
            <Route
              path="/admin/orderdetails/:orderid"
              element={<OrderDetails />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
