import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  // clienttotal: localStorage.getItem("clienttotal")
  //   ? JSON.parse(localStorage.getItem("clienttotal"))
  //   : [],
  clienttotal: [],
  walletHistorytotal: [],
  isLoading: true,
  clientLoading: true,
  walletHistoryLoading: true,
};

export const getClient = createAsyncThunk(
  "Client/getClient",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/client/all`;
      const resp = await axios(url);
      return resp.data.clients;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const clientUpdate = createAsyncThunk(
  "Client/clientUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/Clientid/${formData.claintid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

export const getClientByMob = createAsyncThunk(
  "Client/getClientByMob",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not found",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/loginclient`;
      resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const validatePhoneNumber = createAsyncThunk(
  "Client/validatePhoneNumber",
  async (mobile, thunkAPI) => {
    let resp = {
      success: false,
      message: "new Mobile",
    };
    try {
      const url = `${Baseurl}/api/v1/client/mobile/${mobile}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const clientPost = createAsyncThunk(
  "client/clientPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);
export const walletTransitionPost = createAsyncThunk(
  "clientAll/walletTransitionPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/wallet/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);
const ClientSlice = createSlice({
  name: "clientAll",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle getClient cases
      .addCase(getClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.clienttotal = action.payload;
        localStorage.setItem("clienttotal", JSON.stringify(state.clienttotal));
        state.isLoading = false;
        state.clientLoading = false;
      })
      .addCase(getClient.rejected, (state) => {
        state.isLoading = false; // Corrected to stop loading on failure
      })

      // Handle clientPost cases
      .addCase(clientPost.pending, (state) => {
        state.clientLoading = true;
      })
      .addCase(clientPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.clienttotal = [...state.clienttotal, action.payload.client];
          localStorage.setItem(
            "clienttotal",
            JSON.stringify(state.clienttotal)
          );
        }
        state.clientLoading = false;
      })
      .addCase(clientPost.rejected, (state) => {
        state.clientLoading = false; // Corrected to stop loading on failure
      })

      // Handle walletTransitionPost cases
      .addCase(walletTransitionPost.pending, (state) => {
        state.walletHistoryLoading = true;
      })
      .addCase(walletTransitionPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.walletHistorytotal = [
            ...state.walletHistorytotal,
            action.payload.wallet,
          ];
        }
        state.walletHistoryLoading = false;
      })
      .addCase(walletTransitionPost.rejected, (state) => {
        state.walletHistoryLoading = false; // Corrected to stop loading on failure
      });
  },
});
export default ClientSlice.reducer;
