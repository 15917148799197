import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { productDelete } from "../../redux/Product/ProductSlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productList } = useSelector((store) => store.grocery);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const showModal = (product) => {
    setIsDeleteOpen(true);
    setSelectedProduct(product);
  };

  const handleOk = () => {
    setIsDeleteOpen(false);
  };

  const handleCancel = () => {
    setIsDeleteOpen(false);
  };
  const handleDelete = async () => {
    setIsDeleteOpen(false);
    await dispatch(productDelete(selectedProduct._Id));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <div className="flex flex-wrap items-center justify-end py-1 px-5">
              <span
                onClick={() => navigate("/admin/addProduct")}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#4d44b5",
                  color: "#fff",
                }}
                className="py-2 px-5 inline-flex items-center justify-center font-semibold tracking-wide align-middle duration-500 text-sm text-center bg-primary hover:bg-primary-500 text-white rounded"
              >
                <i className="ph ph-plus-circle text-lg/none me-3" /> Add
                Product
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div
            className="shadow rounded-lg bg-white dark:bg-default-50"
            style={{ marginBottom: "80px" }}
          >
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Product
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        ProductId
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Image
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Show
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Recommeded
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Hot Products
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Trending
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Offer
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList &&
                      productList.map((indProduct, index) => (
                        <tr>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            {index + 1}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                            style={{ color: "green", cursor: "pointer" }}
                          >
                            {indProduct.ProductId}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            {indProduct.ItemName}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indProduct.PackSizes.length> 0 ? (
                              <>
                                <img
                                  src={indProduct.PackSizes[0].ImgUrlMbl}
                                  alt="catimg"
                                  height="50"
                                  width="50"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Switch
                              {...label}
                              defaultChecked={indProduct.status ? true : false}
                            />
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Switch
                              {...label}
                              defaultChecked={
                                indProduct.Recommends ? true : false
                              }
                            />
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Switch
                              {...label}
                              defaultChecked={
                                indProduct.HotProducts ? true : false
                              }
                            />
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Switch
                              {...label}
                              defaultChecked={
                                indProduct.Trending ? true : false
                              }
                            />
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Switch
                              {...label}
                              defaultChecked={indProduct.Offers ? true : false}
                            />
                          </td>
                          <td
                            scope="col"
                            className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Link
                              to={`/admin/editProduct/${indProduct.ProductId}`}
                            >
                              <button
                                style={{
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                }}
                              >
                                Edit
                              </button>
                            </Link>
                          </td>
                          <td
                            scope="col"
                            className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Link>
                              <button
                                onClick={() => showModal(indProduct)}
                                style={{
                                  backgroundColor: "#ff0000",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                }}
                              >
                                Delete
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Basic Modal"
          open={isDeleteOpen}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => setIsDeleteOpen(false)}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>
            Do you want to delete Product With ProductId:{" "}
            <span style={{ color: "red" }}>{selectedProduct.ProductId}</span>
          </p>
        </Modal>
      </div>
    </>
  );
};

export default ProductList;
