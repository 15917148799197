import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/Authentication/AuthSlice";
import { MdAdminPanelSettings } from "react-icons/md";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { GrUserAdmin } from "react-icons/gr";
import { FaFileArchive } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { CiFileOn } from "react-icons/ci";
import { RiFileCopy2Fill } from "react-icons/ri";
import { IoMdPerson } from "react-icons/io";
import { GoChecklist } from "react-icons/go";
import { IoIosImages } from "react-icons/io";
import { TbReportSearch } from "react-icons/tb";
import { FaBook } from "react-icons/fa6";
import { CiBellOn } from "react-icons/ci";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { FaListUl } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";

const Hadear = () => {
  const { loginData, showMenu, role } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(false);
  const handleFullScreen = () => {
    if (!isFullScreen) {
      // Request fullscreen
      document.documentElement
        .requestFullscreen()
        .then(() => setFullScreen(!isFullScreen))
        .catch((err) =>
          console.error("Error attempting to enable full-screen mode", err)
        );
    } else {
      // Exit fullscreen
      document
        .exitFullscreen()
        .then(() => setFullScreen(!isFullScreen))
        .catch((err) =>
          console.error("Error attempting to exit full-screen mode", err)
        );
    }
  };

  const handleEscapeKey = () => {
    setFullScreen(false);
    console.log("hii manoj");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleEscapeKey();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const logoutClick = () => {
    dispatch(authActions.signout());
    navigate("/");
  };
  const handleMenu = (value) => {
    dispatch(authActions.setActiveMenu(value));
  };
  const closeShowMenu = () => {
    dispatch(authActions.setShowMenu(""));
  };
  const handleShowMenu = (value) => {
    if (showMenu === value) {
      dispatch(authActions.setShowMenu(""));
    } else {
      dispatch(authActions.setShowMenu(value));
    }
  };
  return (
    <>
      <div
        className="min-h-screen flex flex-col lg:ps-64 w-full"
        ref={tableRef}
      >
        <div
          id="application-sidebar"
          className="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed inset-y-0 start-0 lg:z-30 z-60 w-64 bg-white border-e border-default-200 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-default-50 rtl:hs-overlay-open:translate-x-0 rtl:translate-x-full rtl:lg:translate-x-0 print:hidden"
          style={{ backgroundColor: "#4d44b5" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: ".5px dashed #fff",
                padding: "10px 20px",
                gap: "15px",
              }}
            >
              <Link to="/admin">
                <img
                  src="/assets/brand.jpeg"
                  alt="logo"
                  style={{ borderRadius: "50%", height: "40px" }}
                />
              </Link>
              <Link to="/admin">
                <div
                  style={{ color: "#fff", fontSize: "22px", fontWeight: "600" }}
                >
                  KisanGhar
                </div>
              </Link>
            </div>
          </div>
          <div
            className="h-[calc(100%-72px)] p-2"
            data-simplebar
            style={{ overflowY: "scroll" }}
          >
            <ul className="admin-menu w-full flex flex-col gap-1.5">
              <li className="menu-item">
                <Link
                  className={
                    "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                  }
                  to="/admin"
                >
                  <i className="ph-duotone ph-gauge text-xl" />
                  Dashboard
                </Link>
              </li>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("superCategory")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                  >
                    <MdAdminPanelSettings />
                    Super Category
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "superCategory" ? (
                    <div>
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium  rounded-md transition-all menuBg"
                            }
                            to="/admin/addsupersategory"
                          >
                            <IoMdPerson />
                            Add Super Category
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/listsupersategory"
                          >
                            <GoChecklist />
                            List Super Category
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("Category")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                  >
                    <MdAdminPanelSettings />
                    Category
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "Category" ? (
                    <div>
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium  rounded-md transition-all menuBg"
                            }
                            to="/admin/addcategory"
                          >
                            <IoMdPerson />
                            Add Category
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/listcategory"
                          >
                            <GoChecklist />
                            List Category
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("subCategory")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md menuBg"
                  >
                    <RiFileCopy2Fill />
                    sub Category
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "subCategory" ? (
                    <div id="menuPages">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addsubcategory"
                          >
                            <TfiWrite />
                            Add subcategory
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all  menuBg"
                            }
                            to="/admin/listsubcategory"
                          >
                            <TfiWrite />
                            List Sub Category
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("brand")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md menuBg"
                  >
                    <RiFileCopy2Fill />
                    Brand
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "brand" ? (
                    <div id="menuPages">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addBrand"
                          >
                            <TfiWrite />
                            Add Brand
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all  menuBg"
                            }
                            to="/admin/listbrand"
                          >
                            <TfiWrite />
                            Brand List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("products")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md menuBg"
                  >
                    <RiFileCopy2Fill />
                    Products
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "products" ? (
                    <div id="menuPages">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addProduct"
                          >
                            <TfiWrite />
                            Add Product
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/productList"
                          >
                            <TfiWrite />
                            Products List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("subscription")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md menuBg"
                  >
                    <RiFileCopy2Fill />
                    Subscription
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "subscription" ? (
                    <div id="menuPages">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addsubscription"
                          >
                            <TfiWrite />
                            Add Subscription
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/listsubscription"
                          >
                            <TfiWrite />
                            Subscription List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("orders")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium  rounded-md transition-all menuBg"
                  >
                    <FaBook />
                    Orders
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "orders" ? (
                    <div id="report">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/order/bookedOrder"
                          >
                            <TbReportSearch />
                            Booked Orders
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/order/processedOrder"
                          >
                            <TbReportSearch />
                            Processed Orders
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/order/dispatchedOrder"
                          >
                            <TbReportSearch />
                            Dispatched Orders
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/order/outOfDeliveryOrder"
                          >
                            <TbReportSearch />
                            Out for Delivery
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/order/deliveredOrder"
                          >
                            <TbReportSearch />
                            Delivered Orders
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/order/cancelledOrder"
                          >
                            <TbReportSearch />
                            Cancelled Orders
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("Coupon")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md menuBg"
                  >
                    <RiFileCopy2Fill />
                    Coupon
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "Coupon" ? (
                    <div id="menuPages">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addcoupon"
                          >
                            <TfiWrite />
                            Add Coupon
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/listcoupon"
                          >
                            <TfiWrite />
                            Coupon List
                          </Link>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <div>
                <li className="menu-item">
                  <Link
                    onClick={() => handleShowMenu("SliderBanner")}
                    className=" flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium  rounded-md transition-all menuBg"
                  >
                    <FaBook />
                    Sliders Banner
                    <i className="ph-bold ph-caret-down text-base ms-auto transition-all hs-collapse-open:rotate-180" />
                  </Link>
                  {showMenu === "SliderBanner" ? (
                    <div id="report">
                      <ul className="space-y-2 mt-2 ms-4">
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addslider"
                          >
                            <TbReportSearch />
                            Add Sliders
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/listslider"
                          >
                            <TbReportSearch />
                            List Sliders
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/addsubcatbanner"
                          >
                            <TbReportSearch />
                            Add SubCatBanner
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            to="/admin/listsubcatbanner"
                          >
                            <TbReportSearch />
                            List SubcatBanner
                          </Link>
                        </li>
                        {/* <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            // to="/admin/order/outOfDeliveryOrder"
                          >
                            <TbReportSearch />
                            Add Today Featured
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            // to="/admin/order/deliveredOrder"
                          >
                            <TbReportSearch />
                            Add Best Offers
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            // to="/admin/order/cancelledOrder"
                          >
                            <TbReportSearch />
                            Add Top Banner
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            // to="/admin/order/cancelledOrder"
                          >
                            <TbReportSearch />
                            Add Infocus Today
                          </Link>
                        </li>
                        <li className="menu-item">
                          <Link
                            className={
                              "flex items-center gap-x-3.5 py-2 px-5 text-sm font-medium rounded-md transition-all menuBg"
                            }
                            // to="/admin/order/cancelledOrder"
                          >
                            <TbReportSearch />
                            Add Featured In Week
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
            </ul>
          </div>
        </div>
        <header className="sticky top-0 h-18 flex z-40 w-full border-b border-default-200/80 bg-white/80 backdrop-blur-3xl dark:bg-default-50 print:hidden">
          <nav className="flex items-center gap-4 w-full px-6">
            <div className="lg:hidden flex">
              <button
                type="button"
                className="text-default-500 hover:text-default-600"
                data-hs-overlay="#application-sidebar"
                aria-controls="application-sidebar"
                aria-label="Toggle navigation"
              >
                <i data-lucide="align-justify" className="w-6 h-6" />
              </button>
            </div>
            <div className="lg:hidden flex">
              <Link to="admin-dashboard.html">
                <img
                  src="/assets/brand.jpeg"
                  alt="logo"
                  className="h-10 w-full flex dark:hidden"
                />
              </Link>
            </div>
            <div className="flex items-center gap-4 ms-auto">
              <div className="lg:flex hidden">
                <button
                  data-toggle="fullscreen"
                  onClick={handleFullScreen}
                  className="inline-flex flex-shrink-0 justify-center items-center gap-2 h-10 w-10 rounded-md font-medium text-default-700 align-middle bg-default-100 hover:text-primary transition-all text-xs"
                >
                  {!isFullScreen ? (
                    <>
                      <i className="ph-duotone ph-arrows-out text-2xl" />
                    </>
                  ) : (
                    <>
                      <i className="ph-duotone ph-arrows-in text-2xl" />
                    </>
                  )}
                </button>
              </div>
              <div className="flex">
                <div className="hs-dropdown relative inline-flex">
                  <button
                    id="hs-dropdown-with-header"
                    type="button"
                    className="hs-dropdown-toggle inline-flex justify-center items-center gap-2 flex-shrink-0 rounded-md font-medium text-default-700 align-middle transition-all text-xs"
                  >
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src="/assets/brand.jpeg"
                      alt="Avatar"
                    />
                    <div className="lg:block text-start hidden">
                      <p
                        className="text-xs font-semibold text-default-700"
                        style={{ textTransform: "capitalize" }}
                      >
                        {loginData.name}
                      </p>
                      <p
                        className="text-xs text-default-500 mt-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {loginData.role}
                      </p>
                    </div>
                  </button>
                  <div className="hs-dropdown-menu mt-2 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[12rem] shadow-md rounded-lg p-2 border border-default-200 bg-white dark:bg-default-50">
                    <span
                      onClick={() => logoutClick()}
                      style={{ cursor: "pointer" }}
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm font-medium text-red-400 hover:bg-red-400/10"
                    >
                      <i className="w-4 h-4" data-lucide="log-out" />
                      Log out
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
};

export default Hadear;
