import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { productDelete } from "../../redux/Product/ProductSlice";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import moment from "moment";
import { updateOrder } from "../../redux/Order/OrderSlice";
import { clientUpdate, getClientByMob, walletTransitionPost } from "../../redux/client/clientSlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const DispatchedOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookedOrder } = useSelector((store) => store.order);
  const [filterOrder, setFilterOrder] = useState([]);
  const [orders, setOrders] = useState([]);
  const [temploading, setTempLoading] = useState(true);

  const [selectOrder, setSelectOrder] = useState("");
  const [model, setModel] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/order/orderdispatched`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        const orderTotal = fetchOrder.data.orders;
        setOrders(orderTotal.reverse());
      }
    };
    fetchData();
  }, []);

  const showModal = (e,order) => {
    setIsDeleteOpen(true);
    setSelectOrder(order);
  };
  const handleOk = () => {
    setIsDeleteOpen(false);
  };

  const handleCancel = () => {
    setIsDeleteOpen(false);
  };

  const nextProcesssClick = (e, order) => {
    setModel(true);
    setSelectOrder(order);
  };

  const cancelClick = (e, order) => {
    setModel(false);
  };

  const updateClick = async (e) => {
    e.preventDefault();

    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: date,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: selectOrder.OrderprocessDate.OrderCancelledDate,
      };
    } else {
      var OrderprocessDate = {};
    }

    const formData = {
      Status: 4,
      StatusText: "Out for Delivery",
      orderid: selectOrder._id,
      Delivery: {
        DName: "",
        DMobile: "",
      },
      remark: {
        ...selectOrder.remark,
        OutfordeliveryRemark: "Your item is out for delivery",
      },
      OrderprocessDate: OrderprocessDate,
    };
    const data = await dispatch(updateOrder(formData));
    const finalOrder = orders.filter((order) => order._id !== selectOrder._id);
    setFilterOrder(finalOrder);
    setModel(false);
  };

  const deleteClick = async (e, selectOrder) => {
    var date = new Date();

    date.setDate(date.getDate());

    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: selectOrder.OrderprocessDate.OutfordeliveryDate,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: date,
      };
    } else {
      var OrderprocessDate = {};
    }

    const formData = {
      StatusText: "Order Cancelled",
      Status: 0,

      orderid: selectOrder._id,
      remark: {
        OrderCancelRemark: "Your delivery is cancelled",
      },
      OrderprocessDate: OrderprocessDate,
    };
    dispatch(updateOrder(formData));

    const getClientForm = {
      Mobile: selectOrder.Mobile,
    };

    const getOrderClient = await dispatch(getClientByMob(getClientForm));

    let getclient = getOrderClient.payload.client;
    if (selectOrder.CurrentStatus === "Not Paid") {
      if (selectOrder.wallet > 0) {
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          claintid: selectOrder.ClientId,
        };
        dispatch(clientUpdate(formDta));
        const walletForm = {
          ClientId: getclient._id,
          previousAmount: getclient.WalletBalance,
          availableAmount:
            Number(getclient.WalletBalance) + Number(selectOrder.wallet),
          transactionAmount: Number(selectOrder.wallet),
          orderId: selectOrder._id,
          Type: "Wallet Credit on Order Cancel",
          ClientName: getclient.Name,
          Mobile: getclient.Mobile,
        };
        await dispatch(walletTransitionPost(walletForm));
      }
    } else if (selectOrder.CurrentStatus === "Paid") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) + Number(selectOrder.TotalAmount),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) + Number(selectOrder.wallet),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    } else if (selectOrder.CurrentStatus === "Partial Payment") {
      const formDta = {
        WalletBalance:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        claintid: selectOrder.ClientId,
      };
      dispatch(clientUpdate(formDta));
      const walletForm = {
        ClientId: getclient._id,
        previousAmount: getclient.WalletBalance,
        availableAmount:
          Number(getclient.WalletBalance) +
          Number(selectOrder.TotalAmount) -
          Number(selectOrder.GrandTotal),
        transactionAmount: Number(selectOrder.wallet),
        orderId: selectOrder._id,
        Type: "Wallet Credit on Partial Order Cancel",
        ClientName: getclient.Name,
        Mobile: getclient.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
    }
    const finalOrder = orders.filter((order) => order._id !== selectOrder._id);
    setFilterOrder(finalOrder);
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div
            className="shadow rounded-lg bg-white dark:bg-default-50"
            style={{ marginBottom: "80px" }}
          >
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden  table-striped">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        OrderID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        ClientID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Client Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Order Date Time
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        NetPayable
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        PaymentMode
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        PaymentStatus
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Coupon Title
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Coupon Description
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders &&
                      orders.map((indOrder, index) => (
                        <tr
                          style={{
                            background: index % 2 === 0 ? "#eee" : "#fff",
                          }}
                        >
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            {index + 1}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                            style={{ color: "green", cursor: "pointer" }}
                          >
                            {indOrder._id.slice(18)}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            {indOrder.ClientId.slice(18)}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.ClientName}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {moment(indOrder.createdAt).format("MMM/DD/YYYY")}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.Address}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.TotalAmount}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.GrandTotal}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.PaymentMode}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.PaymentStatus}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.Mobile}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          ></td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          ></td>
                          <td
                            scope="col"
                            className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Link>
                              <button
                                style={{
                                  backgroundColor: "#1976d2",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                }}
                                onClick={(e) => nextProcesssClick(e, indOrder)}
                              >
                                Proceed
                              </button>
                            </Link>
                          </td>
                          <td
                            scope="col"
                            className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            <Link>
                              <button
                                style={{
                                  backgroundColor: "#ff0000",
                                  color: "#fff",
                                  padding: "10px 20px",
                                  borderRadius: "5px",
                                }}
                                onClick={(e) => showModal(e, indOrder)}
                              >
                                Cancel
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Basic Modal"
          open={model}
          onCancel={cancelClick}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => setModel(false)}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={(e) => updateClick(e)}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>
            Do You Want <span style={{ color: "red" }}>Order Dispatched</span>{" "}
            this orderId:
            {selectOrder !== "" ? (
              <>
                <span style={{ color: "red" }}>
                  {selectOrder._id.slice(18)}
                </span>
              </>
            ) : (
              <></>
            )}
          </p>
        </Modal>

        <Modal
          title="Basic Modal"
          open={isDeleteOpen}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => setIsDeleteOpen(false)}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={() => deleteClick()}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>
            Do You Want to <span style={{ color: "red" }}>Cancel</span> this
            orderId:
            {selectOrder !== "" ? (
              <>
                <span style={{ color: "red" }}>
                  {selectOrder._id.slice(18)}
                </span>
              </>
            ) : (
              <></>
            )}
          </p>
        </Modal>
        {/* <Modal
          title="Basic Modal"
          open={isDeleteOpen}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => setIsDeleteOpen(false)}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={() => handleDelete()}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>
            Do you want to delete Product With ProductId:{" "}
            <span style={{ color: "red" }}>{selectedProduct.ProductId}</span>
          </p>
        </Modal> */}
      </div>
    </>
  );
};

export default DispatchedOrder;
